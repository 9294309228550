<template>
  <v-row>
    <v-col cols="12">
      <v-row>
        <v-col cols="12" xl="3">
          <v-autocomplete
            label="Invoice"
            outlined
            flat
            dense
            background-color="white"
            :items="invoices"
            item-text="invoiceNumber"
            v-model="invoice"
            return-object
            @change="onChangeInvoice"
          ></v-autocomplete>
        </v-col>
        <v-col cols="12" xl="3">
          <v-autocomplete
            label="Bank"
            outlined
            flat
            dense
            background-color="white"
            :items="templateBanks"
            item-text="bankName"
            item-value="id"
            v-model="params.templateBankId"
          ></v-autocomplete>
        </v-col>
        <v-col cols="12" xl="3">
          <v-checkbox label="Template Huawei" v-model="huawei"></v-checkbox>
        </v-col>
        <v-col cols="12" xl="3">
          <v-btn color="success" @click="preview">
            <v-icon>
              mdi-printer-eye
            </v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: "print-invoice",
  data() {
    return {
      form: {},
      params: {
        invoiceId: null,
        templateBankId: null,
        type: "",
      },
      componentKey: 0,
      invoices: [],
      templateBanks: [],
      invoice: {},
      huawei: false,
    };
  },
  methods: {
    onChangeInvoice(val) {
      this.params.type = val.type;
      this.params.invoiceId = val.id;
    },
    async preview() {
      let route;
      if (this.params.type === "DP") {
        if (this.huawei) {
          route = this.$router.resolve({
            name: "Print Invoice Preview Huawei",
            query: this.params,
          });
        } else {
          route = this.$router.resolve({ name: "Print Invoice Preview DP", query: this.params });
        }
      } else if (this.params.type === "Proforma") {
        if (this.huawei) {
          route = this.$router.resolve({
            name: "Print Invoice Preview Huawei",
            query: this.params,
          });
        } else {
          route = this.$router.resolve({
            name: "Print Invoice Preview Proforma",
            query: this.params,
          });
        }
      } else {
        if (this.huawei) {
          route = this.$router.resolve({
            name: "Print Invoice Preview Huawei",
            query: this.params,
          });
        } else {
          route = this.$router.resolve({ name: "Print Invoice Preview", query: this.params });
        }
      }
      window.open(route.href, "_blank");
    },
    async getInvoices() {
      await this.$store.dispatch("saleInvoice/getInvoices", { proforma: true }).then(response => {
        this.invoices = response.data;
      });
    },
    async getTemplateBanks() {
      await this.$store.dispatch("templateBank/getAll").then(response => {
        this.templateBanks = response.data;
        this.params.templateBankId = response.data.filter(x => x.defaultSetting)[0].id;
      });
    },
    getInitialData() {
      this.$store.commit("SET_LOADER", true);
      Promise.all([this.getInvoices(), this.getTemplateBanks()])
        .then(() => this.$store.commit("SET_LOADER", false))
        .catch(() => {
          this.$store.commit("SET_LOADER", false);
          this.$store.dispatch("toast", {
            type: "error",
            message: "Ada masalah dengan aplikasi, mohon hubungi admin aplikasi",
          });
        });
    },
  },
  mounted() {
    this.getInitialData();
  },
};
</script>

<style></style>
